import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Paper,
  Grid,
  Icon,
  CssBaseline,
  Typography,
  Link,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AppBarComponent from '../layout/AppBar/AppBarComponent';
import { AuthConsumer } from '../Auth';
import footerLogo from '@michelin/acid-components/assets/images/logos/Michelin_C_S_WhiteBG_RGB_0621-01.svg';

import xlate, { getCurrentLanguage, setCurrentLanguage } from '../i18n';
import tiles from './Tiles';
import messages from './Messages';
import TermsOfUseDialog from './TermsOfUseDialog';
import PrivayPolicyDialog from './PrivacyPolicyDialog';
import TermsOfUseDialogFrench from './TermsOfUseDialogFrench';
import PrivayPolicyDialogFrench from './PrivacyPolicyDialogFrench';
import LoadingBounce from './LoadingBounce';
import TileCard from './TileCard';
import { styles } from './styles';

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function setCanvasMode(mode) {
  sessionStorage.setItem('canvasMode', mode);
}

function getCanvasMode() {
  return sessionStorage.getItem('canvasMode');
}

function LandingPageAuthComponent(classes) {
  return (
    <AuthConsumer>
      {(props) => (
        <LandingPageComponent
          userProfile={props.auth.userProfile}
          {...props}
          {...classes}
        />
      )}
    </AuthConsumer>
  );
}

class LandingPageComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      language: getCurrentLanguage(),
      //   showWelcome: false,
      //   showUserForm: false,
      //   showVerifyEmail: false,
      //   userData: {}
    };
  }

  /**
   * True if tile does not require login.
   * If login required, check for login. False if not logged in.
   * Auth0 login required? False if B2B login is active.
   * Else return true.
   *
   * @memberof LandingPageComponent
   */
  checkLogin = (tile) => {
    if (!tile.requireLogin) return true;

    if (!this.props.userProfile) return false;

    // if (this.props.userProfile && this.props.userProfile.welcome_screen) {}

    if (tile.requireAuth0Login) {
      return this.props.userProfile.issuer !== 'michelinb2b.com';
    }

    return true;
  };

  launchButton = (tile) => {
    if (tile.key === 'techAdmin')
      if (this.checkLogin(tile)) {
        window.location = tile.link;
      }
  };

  handleLanguage = (language) => {
    setCurrentLanguage(language);
    this.setState({ language });
  };

  render() {
    const { classes, auth } = this.props;
    const { language } = this.state;
    const canvasMode = getCanvasMode();
    if (canvasMode === 'true') {
      return <LoadingBounce style={{ marginTop: '50vh' }} />;
    }
    
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBarComponent
          language={language}
          setCurrentLanguage={(language) => this.handleLanguage(language)}
        />
        <main className={classes.layout}>
          <div className={classes.heroContainer}>
            <Typography variant='body1' align='center' gutterBottom>
              {xlate('landing.launch')}
            </Typography>
            <Typography variant='body1' align='center' gutterBottom>
              {xlate('landing.authenticate')}
            </Typography>
          </div>

          {messages
            .filter((message) => message.visible)
            .map((message) => (
              <Paper className={classes.paper} elevation={4} key={message.key}>
                <Typography variant='h5' component='h3'>
                  {xlate(message.title)}
                </Typography>
                <Typography component='p'>
                  {xlate(message.description)}
                </Typography>
                {message.links.map((link) => (
                  <Link
                    key={link.key}
                    href={link.href}
                    className={classes.link}
                  >
                    <Icon fontSize='small' className={classes.linkIcon}>
                      {link.icon}
                    </Icon>
                    {link.label}
                  </Link>
                ))}
              </Paper>
            ))}

          <Grid container alignItems='stretch' alignContent='stretch'>
            {tiles
              .filter((tile) => tile.visible(this.props.userProfile))
              .map((tile) => (
                <TileCard key={tile.key} tile={tile} userProfile={this.props.userProfile ? this.props.userProfile : {}} auth={auth} />
              ))}
          </Grid>
        </main>

        {/* Footer */}
        <footer className={classNames(classes.footer, classes.layout)}>
          <Grid container spacing={10} justifyContent='center'>
            <Grid item>
              <img
                alt='Home Page'
                src={footerLogo}
                className={classes.footerLogo}
              />
              <Typography variant='caption' align='center'>
                {xlate('landing.copyright')}
                {language === 'fr-CA' ? (
                  <>
                    <TermsOfUseDialogFrench /> | <PrivayPolicyDialogFrench />
                  </>
                ) : (
                  <>
                    <TermsOfUseDialog /> | <PrivayPolicyDialog />
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        </footer>
      </React.Fragment>
    );
  }
}

LandingPageComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

LandingPageAuthComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LandingPageAuthComponent);

export { setCanvasMode, getCanvasMode, inIframe };
