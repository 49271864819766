export const styles = (theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  toolbarTitle: {
    flex: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  heroContainer: {
    margin: '0 auto',
    padding: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 4}px 0`,
  },
  cardActive: {
    cursor: 'pointer',
  },
  cardDisabled: {
    cursor: 'not-allowed',
    opacity: '.5',
  },
  cardHeaderSmall: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.main,
    padding: 4,
    fontWeight: 'bold',
    // fontSize: "2em",
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.main,
    fontSize: '1.5em',
  },
  cardHeaderActive: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    cursor: 'pointer',
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  footer: {
    marginTop: theme.spacing.unit * 8,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit * 6}px 0`,
  },
  logo: {
    height: '100px',
  },
  footerLogo: {
    height: '90px',
    marginBottom: '-12px',
  },
  textHeader: {
    color: '#FFFFFF',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  avatarActive: {
    color: theme.palette.primary.main,
    backgroundColor: '#FFFFFF',
  },
  avatarSmall: {
    height: 24,
    width: 24,
  },
  img: {
    width: '50px',
    height: '50px',
  },
  imgSmall: {
    width: '24px',
    height: '24px',
  },
  titleText: {
    color: '#FFFFFF',
  },
  username: {
    color: '#FFFFFF',
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 4,
    backgroundColor: '#fff8dc',
  },
  link: {
    marginRight: theme.spacing.unit * 2,
  },
  linkIcon: {
    fontSize: '16px',
    position: 'relative',
    top: theme.spacing.unit / 2,
    marginRight: theme.spacing.unit / 2,
  },
});
