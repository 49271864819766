/* eslint-disable max-len */

const getOptionsApiUrl = () => {
  if (origin) {
    if (origin.includes('localhost') || origin.endsWith('.chaos') || origin.includes('-dev.')) {
      return 'https://apigateway.oncall-dev.misp-solutions.com/fna-saml/';
    }

    if (origin.includes('-test.')) {
      return 'https://apigateway.oncall-test.misp-solutions.com/fna-saml/';
    }

    if (origin.includes('-staging')) {
      return 'https://apigateway.oncall-staging.misp-solutions.com/fna-saml/';
    }
  }

  return 'https://apigateway.oncall-prod.misp-solutions.com/fna-saml/';
};

const getMechanicalURL = async (token) => {
  const result = {
    url: '',
    samlResponse: '',
    httpMethod: 'GET',
  };

  try {
    const request = await (fetch(`${getOptionsApiUrl()}launch-pad`, {
      method: 'GET',
      cache: 'no-cache',
      mode: 'cors',
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${token}`,
      },
    }));

    const apiResponse = await request.json();

    if (request.status === 200) {
      result.samlResponse = apiResponse.samlResponse;
      result.httpMethod = apiResponse.httpMethod;
      result.url = apiResponse.url;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('get error', e);
  }

  return result;
};

export default getMechanicalURL;
