import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardHeader,
    CardContent,
    Grid,
    Icon,
    Avatar,
    Typography,
    Link,
  } from '@material-ui/core';
  import { withStyles } from '@material-ui/core/styles';
  import { styles } from './styles';
  import clsx from 'clsx';  
  import xlate, { getCurrentLanguage, setCurrentLanguage } from '../i18n';
  import MechanicalSAMLTileCard from './MechanicalSAMLTileCard';
  import MechanicalTileCard from './MechanicalTileCard';

  class TileCard extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        language: getCurrentLanguage(),
      };
    }
  
    /**
     * True if tile does not require login.
     * If login required, check for login. False if not logged in.
     * Auth0 login required? False if B2B login is active.
     * Else return true.
     *
     * @memberof LandingPageComponent
     */
    checkLogin = (tile) => {
      if (!tile.requireLogin) return true;
  
      if (!this.props.userProfile) return false;
  
      // if (this.props.userProfile && this.props.userProfile.welcome_screen) {}
  
      if (tile.requireAuth0Login) {
        return this.props.userProfile.issuer !== 'michelinb2b.com';
      }
  
      return true;
    };
  
    launchButton = (tile) => {
      if (tile.key === 'techAdmin')
        if (this.checkLogin(tile)) {
          window.location = tile.link;
        }
    };

    handleLanguage = (language) => {
      setCurrentLanguage(language);
      this.setState({ language });
    };
  
    render() {
      const { classes, tile, userProfile, auth } = this.props;

      if (tile.key === 'mechanicalService') {
        if (tile.isSAML) {
          return <MechanicalSAMLTileCard tile={tile} userProfile={userProfile} auth={auth} />
        }

        return <MechanicalTileCard tile={tile} userProfile={userProfile} auth={auth} />
      }

      return (
        <Grid
          item
          key={tile.key}
          style={{ padding: 8 }}
          {...tile.sizes}
        >
        <Link
          underline='none'
          href={
            this.checkLogin(tile)
                ? tile.link && tile.link.url
                ? tile.link.url
                : tile.link
                : undefined
            }
            target={
            tile.link && tile.link.url ? tile.link.url : '_blank'
          }
        >
          <Card
            raised
            className={
                this.checkLogin(tile)
                ? classes.cardActive
                : classes.cardDisabled
            }
          >
            <CardHeader
              avatar={
                <Avatar
                    className={clsx(
                    classes.avatar,
                    classes.avatarSmall,
                    this.checkLogin(tile) && classes.avatarActive
                    )}
                >
                    {tile.key === 'maestroCentral' ? (
                    <img
                        className={classes.imgSmall}
                        src={tile.image}
                        alt='Michelin Central'
                    />
                    ) : (
                    <Icon fontSize='small'>{tile.image}</Icon>
                    )}
                </Avatar>
              }
              title={xlate(tile.title)}
              subheader={xlate(tile.subheader)}
              disableTypography={true}
              titleTypographyProps={{ align: 'center' }}
              subheaderTypographyProps={{ align: 'center' }}
              className={clsx(
              classes.cardHeaderSmall,
              this.checkLogin(tile) && classes.cardHeaderActive
              )}
            />
            <CardContent style={{ marginBotton: 0 }}>
              {tile.description && (
                <Typography variant='body1'>
                    {xlate(tile.description)}
                </Typography>
              )}
              {tile.callout && tile.callout.title && (
                <Typography
                    variant='body2'
                    style={{ fontSize: '1.2em', fontWeight: 'bold' }}
                >
                    {xlate(tile.callout.title)}
                </Typography>
              )}
              {tile.callout && tile.callout.description && (
                <Typography variant='body1'>
                    {xlate(tile.callout.description)}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Link>
      </Grid>
      );
    }
  }

TileCard.propTypes = {
  classes: PropTypes.object.isRequired,
  tile: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
};

TileCard.propTypes = {
  classes: PropTypes.object.isRequired,
  tile: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
};

export default withStyles(styles)(TileCard);
