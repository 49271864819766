import React, { useState, useRef } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Grid,
    Icon,
    Avatar,
    Typography,
  } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import clsx from 'clsx';  
import xlate from '../i18n';
import getMechanicalURL from '../apis/getMechanicalURL';

function MechanicalSAMLTileCard({ classes, tile, userProfile, auth }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const formRef = useRef(null);

  const [url, setUrl] = useState('');
  const [samlResponse, setSamlResponse] = useState('');
  const [formMethod, setFormMethod] = useState('POST');

  React.useEffect(() => {
    let value = tile.requireLogin && userProfile;

    setIsLoggedIn(value);
  }, [tile, userProfile]);

  React.useEffect(() => {
    const getURL = () => {
      (async function fetch() {
        const token = auth.idToken;
        const result = await getMechanicalURL(token);

        setUrl(result.url);
        setSamlResponse(result.samlResponse);
        setFormMethod(result.httpMethod);
      }());
    };

    getURL();
  }, [tile, userProfile, auth]);
    
  const handleClick = () => {
    formRef.current.submit();
  }

  return (
    <>
      <form ref={formRef} action={url.split('?')[0]} method={formMethod}>
        <input name="SAMLResponse" value={samlResponse} hidden />
      </form>
      <Grid
        item
        key={tile.key}
        style={{ padding: 8 }}
        {...tile.sizes}
      >
        <Card
          raised
          className={
              isLoggedIn
              ? classes.cardActive
              : classes.cardDisabled
          }
          onClick={handleClick}
        >
          <CardHeader
            avatar={
              <Avatar
                  className={clsx(
                  classes.avatar,
                  classes.avatarSmall,
                  isLoggedIn && classes.avatarActive
                  )}
              >
                <Icon fontSize='small'>{tile.image}</Icon>
              </Avatar>
            }
            title={xlate(tile.title)}
            subheader={xlate(tile.subheader)}
            disableTypography={true}
            titleTypographyProps={{ align: 'center' }}
            subheaderTypographyProps={{ align: 'center' }}
            className={clsx(
              classes.cardHeaderSmall,
              isLoggedIn && classes.cardHeaderActive
            )}
          />
          <CardContent style={{ marginBotton: 0 }}>
            {tile.description && (
              <Typography variant='body1'>
                  {xlate(tile.description)}
              </Typography>
            )}
            {tile.callout && tile.callout.title && (
              <Typography
                  variant='body2'
                  style={{ fontSize: '1.2em', fontWeight: 'bold' }}
              >
                  {xlate(tile.callout.title)}
              </Typography>
            )}
            {tile.callout && tile.callout.description && (
              <Typography variant='body1'>
                  {xlate(tile.callout.description)}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default withStyles(styles)(MechanicalSAMLTileCard);
