const messages = [
  {
    key: 'msgCommentsEventViewer',
    title: 'msgCommentsEventViewer.title',
    visible: false,
    description: 'msgCommentsEventViewer.description',
    links: [
      {
        key: 'link1',
        icon: 'mail',
        label: 'services.help@michelin.com',
        href: 'mailto:services.help@michelin.com',
      },
    ],
  },
  {
    key: 'msgComments',
    title: 'msgComments.title',
    visible: true,
    description: 'msgComments.description',
    links: [
      {
        key: 'link1',
        icon: 'mail',
        label: 'services.help@michelin.com',
        href: 'mailto:services.help@michelin.com',
      },
    ],
  },
  {
    key: 'msgCommentsMechanical',
    title: 'msgCommentsMechanical.title',
    visible: true,
    description: 'msgCommentsMechanical.description',
    links: [],
  },
];

export default messages;
