import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Grid,
    Icon,
    Avatar,
    Typography,
    Link,
  } from '@material-ui/core';
  import { withStyles } from '@material-ui/core/styles';
  import { styles } from './styles';
  import clsx from 'clsx';  
  import xlate from '../i18n';
import getMechanicalURL from '../apis/getMechanicalURL';

function MechanicalTileCard({ classes, tile, userProfile, auth }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [url, setUrl] = useState('');

  React.useEffect(() => {
    let value = tile.requireLogin && userProfile;

    setIsLoggedIn(value);
  }, [tile, userProfile]);

  React.useEffect(() => {
    const getURL = () => {
      (async function fetch() {
        const token = auth.idToken;
        const result = await getMechanicalURL(token);

        setUrl(result.url);
      }());
    };

    getURL();
  }, [userProfile, auth]);

  return (
    <Grid
      item
      key={tile.key}
      style={{ padding: 8 }}
      {...tile.sizes}
    >
    <Link
      underline='none'
      href={isLoggedIn ? url : undefined}
    >
      <Card
        raised
        className={
          isLoggedIn
            ? classes.cardActive
            : classes.cardDisabled
        }
      >
        <CardHeader
          avatar={
            <Avatar
                className={clsx(
                classes.avatar,
                classes.avatarSmall,
                isLoggedIn && classes.avatarActive
              )}
            >
              <Icon fontSize='small'>{tile.image}</Icon>
            </Avatar>
          }
          title={xlate(tile.title)}
          subheader={xlate(tile.subheader)}
          disableTypography={true}
          titleTypographyProps={{ align: 'center' }}
          subheaderTypographyProps={{ align: 'center' }}
          className={clsx(
            classes.cardHeaderSmall,
            isLoggedIn && classes.cardHeaderActive
          )}
        />
        <CardContent style={{ marginBotton: 0 }}>
          {tile.description && (
            <Typography variant='body1'>
                {xlate(tile.description)}
            </Typography>
          )}
          {tile.callout && tile.callout.title && (
            <Typography
              variant='body2'
              style={{ fontSize: '1.2em', fontWeight: 'bold' }}
            >
              {xlate(tile.callout.title)}
            </Typography>
          )}
          {tile.callout && tile.callout.description && (
            <Typography variant='body1'>
                {xlate(tile.callout.description)}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Link>
  </Grid>
  );
};

export default withStyles(styles)(MechanicalTileCard);
